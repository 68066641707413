import { useRef } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const initialState = {
//     name: '',
//     email: '',
//     message: '',
//     subject: ''
// }
toast.configure();
export const Contact = (props) => {
    Aos.init();
    // const [{ name, email, message }, setState] = useState(initialState)

    // const handleChange = (e) => {
    //   const { name, value } = e.target
    //   setState((prevState) => ({ ...prevState, [name]: value }))
    // }
  
    const form = useRef();

    const handleSubmit = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(props.data.serviceId, props.data.templateId, form.current, props.data.userId)
        .then((result) => {
            toast.success('We will get back to you soon!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            form.current.reset()
        }, (error) => {
            toast.success('We could not send you message, please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    return (
    <div id="contact">
        <div id='contact-header'>
            <div className='intro'>
                <div className='overlay'>
                    <div className='container'>
                    <div className='row'>
                        <div className='intro-text' data-aos="zoom-out" data-aos-duration="1000">
                            <h2>
                                {props.data ? props.data.title : 'Loading'}
                            </h2>                       
                            <p>{props.data ? props.data.paragraph : 'Loading'}</p>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className="row">
                <div className='col-md-8'
                    data-aos="fade-right"
                    data-aos-duration="1000">
                    <div className='section-title'>
                        <h2>Get In Touch</h2>
                        <p>
                        Please fill out the form below to send us an email and we will
                        get back to you as soon as possible.
                        </p>
                    </div>
                    <form name='sentMessage' ref={form} validate onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <input
                                        type='text'
                                        id='from_name'
                                        name='from_name'
                                        className='form-control'
                                        placeholder='Name'
                                        required
                                        
                                    />
                                    <p className='help-block text-danger'></p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <input
                                        type='email'
                                        id='reply_to'
                                        name='reply_to'
                                        className='form-control'
                                        placeholder='Email'
                                        required
                                       
                                    />
                                    <p className='help-block text-danger'></p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <input
                                        type='text'
                                        id='subject'
                                        name='subject'
                                        className='form-control'
                                        placeholder='Subject'
                                        required
                                        
                                    />
                                    <p className='help-block text-danger'></p>
                                </div>
                            </div>
                        </div>

                        <div className='form-group'>
                            <textarea
                                name='message'
                                id='message'
                                className='form-control'
                                rows='4'
                                placeholder='Message'
                                required
                               
                            ></textarea>
                            <p className='help-block text-danger'></p>
                        </div>
                        <div id='success'></div>
                        <button type='submit' className='btn btn-custom btn-lg'>
                            Send Message
                        </button>
                    </form>
                </div>
                <div className='col-md-3 contact-info'                 
                    data-aos="fade-left"
                    data-aos-duration="1000">
                    <div className='contact-item'>
                    <h3>Contact Info</h3>
                    <p>
                        <span>
                            <i className='fa fa-map-marker'></i> Address
                        </span>
                        <br/>
                        <a href={props.data ? props.data.urlMap : ''}>{props.data ? props.data.address : 'loading'}</a>
                        
                    </p>
                    </div>
                    <div className='contact-item'>
                    <p>
                        <span>
                            <i className='fa fa-phone'></i> Phone
                        </span><br/>
                        <a href={props.data ? 'tel:+'+props.data.phone : ''}>{props.data ? props.data.phone : 'loading'}</a>
                    </p>
                    </div>
                    <div className='contact-item'>
                    <p>
                        <span>
                        <i className='fa fa-envelope-o'></i> Email
                        </span><br/>
                        <a href={props.data ? 'mailto:'+props.data.email : ''}>{props.data ? props.data.email : 'loading'}</a>
                    </p>
                    </div>
                </div>                
            </div>

          
        </div>
        <div className='container' id="map"
                data-aos="fade-up"
                data-aos-duration="1500">
            <div className='row'>
                <iframe title='map' className='iframe-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53013.96763888505!2d-84.55079038161247!3d33.85427744890636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50c3d75fa13e7%3A0x996104eec4f504a3!2sSmyrna%2C%20GA!5e0!3m2!1sen!2sus!4v1644337933958!5m2!1sen!2sus"  allowFullScreen="" loading="lazy"></iframe>
            </div>
        </div>
    </div>
    )
  }