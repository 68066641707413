import Aos from 'aos';
import 'aos/dist/aos.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';

//TODO: get images from json
export const Header = (props) => {
    const history = useHistory();
    Aos.init();
    return (
      <div>
        <header id='header'>
          <div className='intro'>
            <div className='overlay'>
              {props.data
                ? 
                <Carousel
                  infiniteLoop
                  autoPlay
                  showThumbs={false}
                  transitionTime={2000}
                  interval={6000}
                  dynamicHeight={false}>

                  {props.data.carousel
                    ? props.data.carousel.map((d, i) => (
                        <div key={`${d}-${i}`}>
                          <img  className="img-responsive header-carousel" src={d.image} alt='carousel-slide'/>
                          <div  className="intro-text" data-aos="zoom-out" data-aos-duration="1500">
                            <h1> 
                              {d.title}
                              <span></span>
                            </h1>
                            <p>{d.paragraph}</p>
                          </div>
                        </div>
                      ))
                    : 'loading'}
                </Carousel>
               : 'loading'}
            </div>
          </div>

        </header>
        
        <div id='contact-shortcut'>
          <div className='container pt-5'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <p>Are you interested?</p>
              </div>
              <div className='col-md-6  col-sm-12'>
                <div className='button-group'>
                  <a href={props.data ? 'tel:+'+props.data.phone : ''}>
                    <button className='call btn'>Call Us</button>
                  </a>
                  <button onClick={() => history.push('/contact')} className='contactus btn'>Contact Us</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
  