import { Modal, Effect } from 'react-dynamic-modal';

export const ServiceCard = (data, index, onRequestClose) => {
console.log(data)
    return (
        <Modal
            onRequestClose={onRequestClose}
            effect={Effect.ScaleUp}
            style={{
                content: {
                    margin: '2rem auto',
                }
            }}>
            <div className='container'>
                <h3>{data.data.name}</h3>
                <div className='cardImage'>
                    <img src={data.data.image} alt='service-header'/>
                </div>
                <div>
                    {data.data
                        ? data.data.fullText.map((d, i) => (
                            <p key={`${d}-${i}`}>{d}</p>
                        ))
                        : 'loading'
                    }
                   
                </div>
            </div>
        </Modal>
    )
  }