
export const Intro = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Strife Remodeling</h2>
          <p>
            At Strife Remodeling, A Strife MGMT Company, we are able to assist you in all aspects of construction. This includes the pre-construction process, the designing of your new home or office, or building your home or office from an existing set of construction plans. Continue reading to find out what each of these different stages and services consists of, and how we can help you to make your entire construction project run smoother, start to finish.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-6 col-lg-6' data-aos="fade-up" data-aos-duration="1500">
                  <iframe title={`${d.title}`} width="420" height="345" src={`${d.videoUrl}`}></iframe>
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}