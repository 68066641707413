export const Footer = (props) => {
    return (
        <footer id='footer'>
            <div className='container text-center' data-aos="zoom-out" data-aos-duration="1000">
                <div className="row">
                    <div className="col-md-4">
                        <h3>About Us</h3>
                        <p>We specialize in residential and commercial construction, remodeling, and maintenance. When it comes to new construction, we are able to design your project from scratch and build it, or we can just build from your existing plans.</p>
                    </div>
                    <div className="col-md-4">
                        <h3>Services</h3>
                        <ul>
                            <li>General Contracting</li>
                            <li>Construction</li>
                            <li>Home Builders</li>
                            <li>Home Maintenance</li>
                            <li></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h3>Strife Remodeling</h3>
                        <address>
                            Smyrna, <br/>
                            GA 30082 , United States <br/>
                            404-889-6663
                        </address>
                    </div>
                </div>
                <div>
                    &copy; 2022 Strife Management LLC
                </div>
            </div>
        </footer>
    )
  }
  