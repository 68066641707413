import { ServiceCard } from "./service-card"
import { ModalManager } from 'react-dynamic-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Services = (props) => {
    const openModal = (index, data) => {
      ModalManager.open(<ServiceCard data={data} index={index} onRequestClose={() => true}/>);
    }
    return (
      <div id='services' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Your One Stop Shop</h2>
          </div>
          <div className='row' 
                data-aos="fade-up"
                data-aos-duration="1500">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4 d-flex' onClick={()=> openModal(i, d)}>
                    {' '}
                    {/* <i className={d.icon}></i> */}
                    <div className='service-block'>
                      <div className='service-icon'><FontAwesomeIcon icon={d.icon} /></div>
                    </div>
                    
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
  