export const About = (props) => {
    return (
    <div id="about">
        <div id='about-header'>
            <div className='intro'>
                <div className='overlay'>
                    <div className='container'>
                    <div className='row'>
                        <div className='intro-text' data-aos="zoom-out" data-aos-duration="1000">
                            <h2>
                                {props.data ? props.data.title : 'Loading'}
                            </h2>                       
                            <p>{props.data ? props.data.paragraph : 'Loading'}</p>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className="row">
                <div className="col-xs-12 col-md-6"                    
                    data-aos="fade-right"
                    data-aos-duration="1000">
                    {" "}
                    <img src="img/3606917.jpg" className="img-responsive" alt="" />{" "}
                </div>
                <div className="col-xs-12 col-md-6"
                    data-aos="fade-left"
                    data-aos-duration="1000">
                    <div className="about-text">
                        <h2>Strife Remodeling</h2>
                        <p>{props.data ? props.data.info : "loading..."}</p>
                        <div className="list-style">
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                <ul>
                                    {props.data
                                    ? props.data.itens.map((d, i) => (
                                        <li key={`${d}-${i}`}>{d}</li>
                                        ))
                                    : "loading"}
                                </ul>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                <ul>
                                    {props.data
                                    ? props.data.itens2.map((d, i) => (
                                        <li key={`${d}-${i}`}> {d}</li>
                                        ))
                                    : "loading"}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};
  