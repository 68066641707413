import { useState, useEffect } from "react";
import { Services } from "./components/services";
import { Header } from "./components/header";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Intro } from "./components/intro";
import { Contact } from "./components/contact";
import { About } from "./components/about";
import { Link, Switch, BrowserRouter, Route } from "react-router-dom";
import { Footer } from "./components/footer";
import { Clients } from "./components/clients";
import 'react-toastify/dist/ReactToastify.css';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faDoorOpen, faWindowRestore, faBolt, faDroplet, faWindowClose, faKitchenSet, faHouseChimney, faBridgeWater, faTrowelBricks, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
library.add(fab, faDoorOpen, faWindowRestore, faBolt, faDroplet, faWindowClose, faKitchenSet, faHouseChimney, faBridgeWater, faTrowelBricks, faPeopleRoof);

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function configureFirebase(){
  // Colocar saporra tudo em variavel de ambiente. Mas nao eh o site oficial ainda, entao ta sussa

  // const firebaseConfig = {
  //   apiKey: "AIzaSyCHXOyXIJjnK_-VbI3BdGzUh6HibhLwr5s",
  //   authDomain: "site-sean.firebaseapp.com",
  //   projectId: "site-sean",
  //   storageBucket: "site-sean.appspot.com",
  //   messagingSenderId: "816861564024",
  //   appId: "1:816861564024:web:438b9f26d15e38a637b18d",
  //   measurementId: "G-38814E41QE"
  // };

  // // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);

}

const App = () => {

  configureFirebase();
  
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
 
      <BrowserRouter>
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
          <div className='container'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                data-target='#bs-example-navbar-collapse-1'
              >
                {' '}
                <span className='sr-only'>Toggle navigation</span>{' '}
                <span className='icon-bar'></span>{' '}
                <span className='icon-bar'></span>{' '}
                <span className='icon-bar'></span>{' '}
              </button>
              <Link to="/">
                  <img src="logo.png" className="brand-logo" alt="strife-logo" />
              </Link>
            </div>

            <div
              className='collapse navbar-collapse'
              id='bs-example-navbar-collapse-1'
            >
              <ul className='nav navbar-nav navbar-right'>
                <li>
                  <Link to="/about">
                    About us
                  </Link>
                </li>

                <li>
                  <Link to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>  
        <Switch>          
            <Route path="/about" exact>
              <About data={landingPageData.About} />
            </Route>
            <Route path="/contact" exact>
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/">
              <Header data={landingPageData.Header} />
              <Intro data={landingPageData.Gallery} />
              <Services data={landingPageData.Services} />
              <Clients data={landingPageData.Testimonials}/>
            </Route>
        </Switch>
        <Footer></Footer>
      </BrowserRouter>
  );
};

export default App;
